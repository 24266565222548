const yamlStr = `
openapi: 3.0.0
info:
  x-logo:
    url: "https://edbit.io/images/logo.png"
    backgroundColor: "#FFFFFF"
    altText: "Edbit logo"
  version: v1.0.0
  title: Edbit Credentials API
  description: Welcome to Edbit Credentials API Documentation. Below you can find the endpoints to send your documnets to our API. Our credentials API will send the issuer and reciever data, along with your document(s), to the blockchain. 
  contact:
    name: Edbit Inc.
    email: info@edbit.io
    url: https://edbit.io
  license:
    name: Copyright Edbit Inc. 2022. All rights reserved.

servers:
  - description: SwaggerHub API Auto Mocking
    url: https://virtserver.swaggerhub.com/Ahmed76/Edbit/v1.0.0
  - description: Deployment Server
    url: https://api.edbit.io
components:
  securitySchemes:
    bearerAuth:            # arbitrary name for the security scheme
      type: http
      scheme: bearer
      bearerFormat: JWT    # optional, arbitrary value for documentation purposes
# 2) Apply the security globally to all operations
security:
  - bearerAuth: []   

tags:
  - name: Auth
  - name: Certificate
  - name: User
  - name: Folder
  - name: Token

paths:
# .....login..... #
  /auth/login:
    post:
      tags:
        - Auth
      description: Verifies a user and returns the jwt access token.
      operationId: Login
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              properties:
                email:
                  description: The email that the organization signs up with.
                  type: string
                  example: 'example@abc.com'
                password: 
                  description: The password that the organization signs up with. This password must be at least 10 characters long, contain one number, one uppercase letter, one lowercase letter, and a special character.
                  type: string
                  example: 'Password1!'
      responses:
        200:
          description: User is authenticated.
          content:
            application/json:
              schema:
                type: object
                properties:
                  msg:
                    type: string
                    example: 'Login Successful'
                  accessToken:
                    type: string
                    example: 'eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In......'
                  error:
                    type: boolean
                    example: false
        403:
          description: User is authenticated, but email is not verified.
          content:
            application/json:
              schema:
                type: object
                properties:
                  msg:
                    type: string
                    example: 'Email is not verified!'
                  accessToken:
                    type: string
                    example: 'eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In......'
                  error:
                    type: boolean
                    example: false
        401: 
          description: User is unauthenticated.
          content:
            application/json:
              schema:
                  description: 401 response for login
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Bad Combination email/password'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response for login
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Network error!'
                    error:
                      type: boolean
                      example: true
# .....Signup..... #
  /auth/signup:
    post:
      tags:
        - Auth
      description: Registers a new user.
      operationId: Sign up
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              properties:
                email:
                  description: A valid email address for the organization.
                  type: string
                  example: 'example@abc.com'
                password: 
                  description: The password that the organization signs up with. This password must be at least 10 characters long, contain one number, one uppercase letter, one lowercase letter, and a special character.
                  type: string
                  example: 'Password1!'
      responses:
        200: 
          description: User Successfully signed up.
          content:
            application/json:
              schema:
                description: 200 response for signup
                type: object
                properties:
                  msg:
                    type: string
                    example: 'User Signed Up Successfully'
                  error:
                    type: boolean
                    example: false
        401: 
          description: Sign up Failed.
          content:
            application/json:
              schema:
                  description: 401 response for signup
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Sign up Failed'
                    reason:
                      type: string
                      example: 'An account with the given email already exists.'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response for signup
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Network Error!'
                    error:
                      type: boolean
                      example: true
#.....change password .....#
  /auth/changePassword:
      post:
        security:
          - bearerAuth: []
        tags:
          - Auth
        description: Updates the password of the user.
        operationId: Update user's password.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  password:
                    description: The user's old password.
                    type: string
                    example: "Password1!"
                  newPassword:
                    description: The user's new password.
                    type: string
                    example: "Password2@"
        responses:
          200: 
            description: Information updated successfully.
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: Password updated
                    error:
                      type: boolean
                      example: false
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Network Error, please try again!'
                      error:
                        type: boolean
                        example: true
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
          400:
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 400 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Incorrect Password'
                      error:
                        type: boolean
                        example: true
# .....Issue a certificate.....#
  /certificate/issue:
    post:
      security:
        - bearerAuth: []
      tags:
        - Certificate
      description: Verifies a certificate and returns its links.
      operationId: Issue a certificate
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                file:
                  type: string
                  format: binary
                data: 
                  type: object
                  properties:
                    certificate:
                      type: object
          application/json:
            schema:
              type: object
              properties:
                certificate:
                  type: object
                  properties:
                    folderName: 
                      type: string
                      example: "Fall 2021"
                    type:
                      type: string
                      example: "University Degree"
                    
      responses:
        200: 
          description: File submitted to blockchain successfully
          content:
            application/json:
              schema:
                description: 200 response
                type: object
                properties:
                  txLink:
                    type: string
                    example: 'https://ropsten.etherscan.io/tx/0xa7996da0cf54725fa984145bb1040a97ea0950de740e433c78c922249d8adc95'
                  cerLink:
                    type: string
                    example: 'https://edbit.mypinata.cloud/ipfs/QmPxtK58mDJWavuMAaiumyq3u47nuFVUdUnLyRtYcuwn9E'
                  fileHash:
                    type: string
                    example: 'bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd'
                  msg:
                    type: string
                    example: "File submitted to blockchain successfully"
                  error:
                    type: boolean
                    example: false
                  accessToken:
                    type: string
                    nullable: true
                    example: 'eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In......'
        201:
          description: Certificate issued before.
          content:
            application/json:
              schema:
                  description: 201 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'This Certificate is Already Issued.'
                    cerLink:
                      type: string
                      example: 'https://edbit.mypinata.cloud/ipfs/QmPxtK58mDJWavuMAaiumyq3u47nuFVUdUnLyRtYcuwn9E'
                    fileHash:
                      type: string
                      example: 'bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd'
                    accessToken:
                      type: string
                      nullable: true
                      example: 'eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In......'
        500: 
          description: Network error!.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error Issuing the certificate, please try again!'
                    error:
                      type: string
                      example: "Error msg from the server"
        400: 
          description: The request missing the file.
          content:
            application/json:
              schema:
                  description: 400 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'File Missing!'
                    error:
                      type: boolean
                      example: true
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
# .....verify by hash..... #
  /certificate/verifyByTxhash:
    get:
      tags:
        - Certificate
      description: Verifies a certificate and returns its links.
      operationId: Verify by hash.
      parameters:
        - name: hash
          in: query
          required: true
          description: A valid transaction hash address for an issued certificate
          schema:
            type: string
            example: '0xb1f5be727550cdb36041ac651f8bfda7b885300adf3145087cacb854b8bd2b9c'
      responses:
        200: 
          description: Certificate is valid.
          content:
            application/json:
              schema:
                description: 200 response
                type: object
                properties:
                  msg:
                    type: string
                    example: This document is authentic and from a verified issuer
                  verified:
                    type: boolean
                    example: true
                  error:
                    type: boolean
                    example: false
                  tx_hash:
                    type: string
                    example: 0xa7996da0cf54725fa984145bb1040a97ea0950de740e433c78c922249d8adc95
                  fileHash:
                    type: string
                    example: 'bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd'
                  fileName:
                    type: string
                    example: certificate.pdf
                  ipfs_cid:
                    type: string
                    example: QmPxtK58mDJWavuMAaiumyq3u47nuFVUdUnLyRtYcuwn9E
                  uid:
                    type: string
                    example: 099cb4e-f7b-4206-ca1-d9483e4d209
                  issued_at:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
                  issuer:
                    type: object
                    properties:
                      uid:
                        type: string
                        example: 099cb4e-f7b-4206-ca1-d9483e4d209
                      first_name:
                        type: string
                        example: John
                        nullable: true
                      last_name:
                        type: string
                        example: Doe
                        nullable: true
                      email:
                        type: string
                        example: JD@llc.com
                      website:
                        type: string
                        example: https://llc.com
                        nullable: true
                      location:
                        type: string
                        example: United States
                        nullable: true
                      organization_name:
                        type: string
                        example: Los Lantos company.
                        nullable: true
                      profile_picture:
                        type: string
                        example: 'https://photourl.com'
                        nullable: true
                      credits:
                        type: number
                        example: 2000
                  
        500: 
          description: Certificate is not found.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'No match could be found for this Document'
                    verified:
                      type: boolean
                      example: false
                    error:
                      type: boolean
                      example: false
                    fileHash:
                      type: string
                      example: ''
                    fileName:
                      type: string
                      example: ''
# .....verify by file..... #
  /certificate/verifyByFile:
    post:
      tags:
        - Certificate
      description: Verifies a certificate and returns its links.
      operationId: Verify By File.
      requestBody:
        required: true
        content:
          multipart/form-data:
            schema:
              type: object
              properties:
                file:
                  type: string
                  format: binary
      responses:
        200: 
          description: Certificate is valid.
          content:
            application/json:
              schema:
                description: 200 response
                type: object
                properties:
                  msg:
                    type: string
                    example: This document is authentic and from a verified issuer
                  verified:
                    type: boolean
                    example: true
                  error:
                    type: boolean
                    example: false
                  tx_hash:
                    type: string
                    example: 0xa7996da0cf54725fa984145bb1040a97ea0950de740e433c78c922249d8adc95
                  fileHash:
                    type: string
                    example: 'bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd'
                  fileName:
                    type: string
                    example: certificate.pdf
                  ipfs_cid:
                    type: string
                    example: QmPxtK58mDJWavuMAaiumyq3u47nuFVUdUnLyRtYcuwn9E
                  uid:
                    type: string
                    example: 099cb4e-f7b-4206-ca1-d9483e4d209
                  issued_at:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
                  
        202: 
          description: Certificate is not found.
          content:
            application/json:
              schema:
                  description: 202 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'No match could be found for this Document'
                    verified:
                      type: boolean
                      example: false
                    error:
                      type: boolean
                      example: true
                    fileHash:
                      type: string
                      example: 'bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd'
                    fileName:
                      type: string
                      example: "certificate_1245.pdf"
        400: 
          description: The request missing the file.
          content:
            application/json:
              schema:
                  description: 400 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'File Missing!'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response for verify by file
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Network Error!'
                    error:
                      type: boolean
                      example: true
                    verified:
                      type: boolean
                      example: false
# .....token price..... #
  /certificate/getDocPriceInAvax:
    get:
      tags:
        - Certificate
      description: Getting the price of one doc.
      operationId: Document price

      responses:
        200:
          description: Information fetched successfuly.
          content:
            application/json:
              schema:
                type: object
                properties:
                  msg:
                    type: string
                    example: 'Price fetched'
                  docPriceInAvax:
                    type: number
                    example: 0.00284883993
                  error:
                    type: boolean
                    example: false
                      
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error message'
                    error:
                      type: boolean
                      example: true
#.....update name, country of the user .....#
  /user/updateName:
      post:
        security:
          - bearerAuth: []
        tags:
          - User
        description: Updates the name and the country of the user.
        operationId: Update user's name.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  firstName:
                    description: The user's first name.
                    type: string
                    example: "John"
                  lastName:
                    description: The user's last name.
                    type: string
                    example: "Doe"
                  location:
                    description: The user's location (country).
                    type: string
                    example: "United States"
        responses:
          200: 
            description: Information updated successfully.
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: Information updated successfully.
                    error:
                      type: boolean
                      example: false
                    accessToken:
                        type: string
                        example: "eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In....."
                        nullable: true
          
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Error updating user information'
                      error:
                        type: boolean
                        example: true
                      
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
#.....update organization name, website of the user .....#
  /user/updateOrganization:
      post:
        security:
          - bearerAuth: []
        tags:
          - User
        description: Updates the organization's name and website.
        operationId: Update user's organization.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  organizationName:
                    description: The organization's name.
                    type: string
                    example: Univesity of Los Santos
                  website:
                    description: The organization's website.
                    type: string
                    example: https://www.uls.edu

        responses:
          200: 
            description: Information updated successfully.
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: Information updated successfully.
                    error:
                      type: boolean
                      example: false
                    accessToken:
                        type: string
                        example: "eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In....."
                        nullable: true
          
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Error updating user information'
                      error:
                        type: boolean
                        example: true
                      
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
#.....get user.....#
  /user/getUser/{uid}:
    get:
      security:
        - bearerAuth: []
      tags:
        - User
      description: Get a list of attributes for a specific user.
      operationId: Get User.
      parameters:
        - name: uid
          in: path
          required: true
          description: User id
          schema:
            type: string
            example: '05b3d555-h8eb-11ec-89d1-0aa33b1a201c'
      responses:
        200: 
          description: Information updated successfully.
          content:
            application/json:
              schema:
                description: 200 response
                type: object
                properties:
                  msg:
                    type: string
                    example: User fetched.
                  error:
                    type: boolean
                    example: false
                  user:
                    type: object
                    properties:
                      first_name:
                        type: string
                        example: John
                        nullable: true
                      last_name:
                        type: string
                        example: Doe
                        nullable: true
                      email:
                        type: string
                        example: JD@llc.com
                      website:
                        type: string
                        example: https://llc.com
                        nullable: true
                      location:
                        type: string
                        example: United States
                        nullable: true
                      organization_name:
                        type: string
                        example: Los Lantos company.
                        nullable: true
                      profile_picture:
                        type: string
                        example: 'https://photourl.com'
                        nullable: true
                      credits:
                        type: number
                        example: 2000
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for adding a folder
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Error fetching users information
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error fetching users information'
                    error:
                      type: boolean
                      example: true
#.....get current user.....#
  /user/getCurrentUser:
    get:
      security:
        - bearerAuth: []
      tags:
        - User
      description: Get a list of attributes for a the current user.
      operationId: Get Current User.
      responses:
        200: 
          description: Information updated successfully.
          content:
            application/json:
              schema:
                description: 200 response
                type: object
                properties:
                  msg:
                    type: string
                    example: User fetched.
                  error:
                    type: boolean
                    example: false
                  user:
                    type: object
                    properties:
                      first_name:
                        type: string
                        example: John
                        nullable: true
                      last_name:
                        type: string
                        example: Doe
                        nullable: true
                      email:
                        type: string
                        example: JD@llc.com
                      website:
                        type: string
                        example: https://llc.com
                        nullable: true
                      location:
                        type: string
                        example: United States
                        nullable: true
                      organization_name:
                        type: string
                        example: Los Lantos company.
                        nullable: true
                      profile_picture:
                        type: string
                        example: 'https://photourl.com'
                        nullable: true
                      credits:
                        type: number
                        example: 2000
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for adding a folder
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Error fetching users information
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error fetching users information'
                    error:
                      type: boolean
                      example: true
# .....upload profile picture.....#
  /user/updatePhoto:
    post:
      security:
        - bearerAuth: []
      tags:
        - User
      description: Update profile picture for the user
      operationId: Update profile picture.
      requestBody:
        content:
          image/png:
            schema:
              type: string
              format: binary
      responses:
        400: 
          description: The request missing the file.
          content:
            application/json:
              schema:
                  description: 400 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'File Missing!'
                    error:
                      type: boolean
                      example: true
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Error updating user's information.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error updating profile picture'
                    error:
                      type: boolean
                      example: true
        200: 
          description: Image Updated.
          content:
            application/json:
              schema:
                description: 200 response
                type: object
                properties:
                  msg:
                    type: string
                    example: Image Updated
                  error:
                    type: boolean
                    example: false
                  accessToken:
                      type: string
                      example: "eyJraW3iOiJdNmhXc3A1dytUdHd1TUR0aWc1RG1xVDA1Ym5qSWlwY1FIWmZ2T09Mazk0PSIsImFsZyI6IlJTMjU2In....."
                      nullable: true
# .....add Folder..... #
  /dir/addFolder:
    post:
      security:
          - bearerAuth: []
      tags:
        - Folder
      description: Add a new folder to the list of folders the user has.
      operationId: Add folder.
      requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  firstName:
                    description: The user's first name.
                    type: string
                    example: "John"
                  lastName:
                    description: The user's last name.
                    type: string
                    example: "Doe"
                  location:
                    description: The user's location (country).
                    type: string
                    example: "United States"
      parameters:
        - name: folderName
          in: query
          required: true
          description: The name of the new folder.
          schema:
            type: string
            example: 'Fall 2022'
      responses:
        200: 
          description: Folder Added
          content:
            application/json:
              schema:
                description: 200 response for add folder
                type: object
                properties:
                  msg:
                    type: string
                    example: 'Folder Added'
                  folder:
                    type: object
                    properties:
                      folderName:
                        type: string
                        example: 'Fall 2021'
                      createdAt:
                        type: object
                        properties:
                          month:
                            type: string
                            example: "02"
                          year:
                            type: string
                            example: "2022"
                          day:
                            type: string
                            example: "18"
                          hour:
                            type: string
                            example: "13"
                          minute:
                            type: string
                            example: "24"
                          second:
                            type: string
                            example: "37"
                      updatedAt:
                        type: object
                        properties:
                          month:
                            type: string
                            example: "02"
                          year:
                            type: string
                            example: "2022"
                          day:
                            type: string
                            example: "18"
                          hour:
                            type: string
                            example: "13"
                          minute:
                            type: string
                            example: "24"
                          second:
                            type: string
                            example: "37" 
                  error:
                    type: boolean
                    example: false
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for adding a folder
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Error updating user's information.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error adding Folder'
                    error:
                      type: boolean
                      example: true
# .....get folders.....#
  /dir/getFolders:
    get:
      security:
        - bearerAuth: []
      tags:
        - Folder
      description: Returns a list of folders.
      operationId: Get folders.
      responses:
        200: 
          description: A list of Folders.
          content:
            application/json:
              schema:
                description: 200 response
                type: array
                items:
                  type: object
                  properties:
                    folderName:
                      type: string
                      example: 'Fall 2021'
                    createdAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
                    updatedAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for adding a folder
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Error updating user's information.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error adding Folder'
                    error:
                      type: boolean
                      example: true                      
# .....get archived folders .....#
  /dir/getArchivedFolders:
    get:
      security:
        - bearerAuth: []
      tags:
        - Folder
      description: Returns a list of archived folders.
      operationId: Get archived folders.
      responses:
        200: 
          description: A list of archived folders.
          content:
            application/json:
              schema:
                description: 200 response
                type: array
                items:
                  type: object
                  properties:
                    folderName:
                      type: string
                      example: 'Fall 2021'
                    createdAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
                    updatedAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Error fetching user's information.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Network Error'
                    error:
                      type: boolean
                      example: true                      
# .....get files....... #
  /dir/getFiles/{folderName}:
    get:
      security:
        - bearerAuth: []
      tags:
        - Folder
      description: Get a list of files for a specific folder.
      operationId: Get Files.
      parameters:
        - name: folderName
          in: path
          required: true
          description: Existing folder name
          schema:
            type: string
            example: 'Fall 2021'
      responses:
        200: 
          description: A list of files.
          content:
            application/json:
              schema:
                description: 200 response
                type: array
                items:
                  type: object
                  properties:
                    fileName:
                      type: string
                      example: 'certificate_sample.pdf'
                    ipfs_cid:
                      type: string
                      example: 'QmYmSwhGaJhWBt9USXaRmThknayrGumwMbii2JbpcxekcV'
                    tx_hash:
                      type: string
                      example: '0x31df321423f37116d9e53db32878b9d4d63e14a998a7a73a4e98ae957a35bfe5'
                    createdAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for getting files
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Network Error!'
                    error:
                      type: boolean
                      example: true
# .....get archived files....... #
  /dir/getArchivedFiles:
    get:
      security:
        - bearerAuth: []
      tags:
        - Folder
      description: Get a list of archived files.
      operationId: Get Archived Files.
      responses:
        200: 
          description: A list of archived files.
          content:
            application/json:
              schema:
                description: 200 response
                type: array
                items:
                  type: object
                  properties:
                    fileName:
                      type: string
                      example: 'certificate_sample.pdf'
                    ipfs_cid:
                      type: string
                      example: 'QmYmSwhGaJhWBt9USXaRmThknayrGumwMbii2JbpcxekcV'
                    tx_hash:
                      type: string
                      example: '0x31df321423f37116d9e53db32878b9d4d63e14a998a7a73a4e98ae957a35bfe5'
                    createdAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for getting files
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error fetching the files'
                    error:
                      type: boolean
                      example: true
# .....get files for archived folder....... #
  /dir/getArchivedFilesForArchivedFolder/{folderName}:
    get:
      security:
        - bearerAuth: []
      tags:
        - Folder
      description: Get a list of files for a specific archived folder.
      operationId: Get Files for archived folder.
      parameters:
        - name: folderName
          in: path
          required: true
          description: Existing folder name
          schema:
            type: string
            example: 'Fall 2021'
      responses:
        200: 
          description: A list of files.
          content:
            application/json:
              schema:
                description: 200 response
                type: array
                items:
                  type: object
                  properties:
                    fileName:
                      type: string
                      example: 'certificate_sample.pdf'
                    ipfs_cid:
                      type: string
                      example: 'QmYmSwhGaJhWBt9USXaRmThknayrGumwMbii2JbpcxekcV'
                    tx_hash:
                      type: string
                      example: '0x31df321423f37116d9e53db32878b9d4d63e14a998a7a73a4e98ae957a35bfe5'
                    createdAt:
                      type: object
                      properties:
                        month:
                          type: string
                          example: "02"
                        year:
                          type: string
                          example: "2022"
                        day:
                          type: string
                          example: "18"
                        hour:
                          type: string
                          example: "13"
                        minute:
                          type: string
                          example: "24"
                        second:
                          type: string
                          example: "37"
        401: 
          description: Unauthorized.
          content:
            application/json:
              schema:
                  description: 401 response for getting files
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Unauthorized'
                    error:
                      type: boolean
                      example: true
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Error fetching the files'
                    error:
                      type: boolean
                      example: true                     
#..... send folder to archive .....#
  /dir/sendFolderToArchive:
      post:
        security:
          - bearerAuth: []
        tags:
          - Folder
        description: Send a folder to archive.
        operationId: Send folder to archive.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  folderName:
                    description: The folder name.
                    type: string
                    example: Web Dev
                  
        responses:
          200: 
            description: Information updated successfully.  
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: Folder sent to archive.
                    error:
                      type: boolean
                      example: false
                    
          
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Error Updating the folder status.'
                      error:
                        type: boolean
                        example: true
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
#..... send file to archive .....#
  /dir/sendFileToArchive:
      post:
        security:
          - bearerAuth: []
        tags:
          - Folder
        description: Send a file to archive.
        operationId: Send file to archive.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  folderName:
                    description: The folder name.
                    type: string
                    example: Web Dev
                  fileName:
                    description: The file name.
                    type: string
                    example: certificate.pdf
                  fileHash:
                    description: The unique hash of the file.
                    type: string
                    example: "bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd"
                  
        responses:
          200: 
            description: Information updated successfully.  
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: File sent to archive.
                    error:
                      type: boolean
                      example: false
                    
          
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Error Updating the file status.'
                      error:
                        type: boolean
                        example: true
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
#..... remove folder from archive .....#
  /dir/removeFolderFromArchive:
      post:
        security:
          - bearerAuth: []
        tags:
          - Folder
        description: Remove a folder from archive.
        operationId: Remove folder from archive.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  folderName:
                    description: The folder name.
                    type: string
                    example: Web Dev
                  
        responses:
          200: 
            description: Information updated successfully.  
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: Folder removed from archive.
                    error:
                      type: boolean
                      example: false
                    
          
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Error Updating the folder status.'
                      error:
                        type: boolean
                        example: true
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
#..... remove file to archive .....#
  /dir/removeFileFromArchive:
      post:
        security:
          - bearerAuth: []
        tags:
          - Folder
        description: Remove a file from archive.
        operationId: Remove a file from archive.
        requestBody:
          required: true
          content:
            application/json:
              schema:
                properties:
                  folderName:
                    description: The folder name.
                    type: string
                    example: Web Dev
                  fileName:
                    description: The file name.
                    type: string
                    example: certificate.pdf
                  fileHash:
                    description: The unique hash of the file.
                    type: string
                    example: "bdf238dd6f6e07174dec911329830e031293492b40093ad15d2648f1857222dd"
                  
        responses:
          200: 
            description: Information updated successfully.  
            content:
              application/json:
                schema:
                  description: 200 response
                  type: object
                  properties:
                    msg:
                      type: string
                      example: File sent back to main folders
                    error:
                      type: boolean
                      example: false
                    
          
          500: 
            description: Error updating user's information.
            content:
              application/json:
                schema:
                    description: 500 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Error Updating the file status.'
                      error:
                        type: boolean
                        example: true
          401: 
            description: Unauthorized.
            content:
              application/json:
                schema:
                    description: 401 response
                    type: object
                    properties:
                      msg:
                        type: string
                        example: 'Unauthorized'
                      error:
                        type: boolean
                        example: true
# .....token price..... #
  /token/getEdbitMetadata:
    get:
      tags:
        - Token
      description: Getting Edbit token metadata.
      operationId: Token Metadata

      responses:
        200:
          description: Information fetched successfuly.
          content:
            application/json:
              schema:
                type: object
                properties:
                  msg:
                    type: string
                    example: 'Information fetched'
                  error:
                    type: boolean
                    example: false
                  edbitMetadata:
                    type: object
                    properties:
                      name:
                        type: string
                        example: EDBIT
                      symbol:
                        type: string
                        example: EDBIT
                      decimals:
                        type: string
                        example: 18
                      totalSupply:
                        type: string
                        example: 50000000000000000000
                      cap:
                        type: string
                        example: 200000000000000000000
                      priceInUSD:
                        type: number
                        example: 0.19780482932602048
                      priceInAVAX:
                        type: number
                        example: 0.00255709040554657
                      
        500: 
          description: Network Error.
          content:
            application/json:
              schema:
                  description: 500 response for getEdbitMetadata
                  type: object
                  properties:
                    msg:
                      type: string
                      example: 'Network Error'
                    error:
                      type: boolean
                      example: true
                      `;

export default yamlStr;
