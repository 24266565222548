import React from "react";
import { RedocStandalone } from "redoc";
// import YAML from 'yamljs';
import yaml from "js-yaml";
import "./App.css";
import yamlStr from "./swagger";

const nativeObject = yaml.load(yamlStr);
function App() {
  return (
    <div className="App">
      <RedocStandalone
        spec={JSON.parse(JSON.stringify(nativeObject))}
        options={{
          nativeScrollbars: false,
          theme: {
            colors: {
              primary: {
                main: "#2a9bd4",
              },
            },
            typography: {
              fontSize: "14px",
              code: {
                fontSize: "13px",
              },
            },
          },
          lazyRendering: true,
          scrollYOffset: 20,
        }}
      />
    </div>
  );
}

export default App;
